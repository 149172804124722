import { useEffect, useState } from 'react';
import useProvideAuth from './useProvideAuth';

export default function usePostData(url, params) {
  const { token, isLogin } = useProvideAuth();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const postData = async (url, params) => {
    let bodyData;
    if (url) {
      if (url === 'customer' || url === 'customer/contract' || url === 'portfolio/details') {
        bodyData = params;
      } else {
        bodyData = {
          data: params,
          force_insert: false
        };
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/v1/${url}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        });

        const responseData = await response.json();
        if (response.ok) {
          setData(responseData);
        } else {
          setError(responseData.detail);
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      postData(url, params);
    }
  }, [isLogin, token, url, params]);

  return { data, error, mutate: postData };
}