import { useEffect, useState } from 'react';
import useProvideAuth from './useProvideAuth';

export default function usePutContractData(url, params) {
  const { token, isLogin } = useProvideAuth();
  const [contractDataPut, setContractDataPut] = useState(null);
  const [contractErrorPut, setContractErrorPut] = useState(null);

  const putContractData = async (url, params) => {
    let bodyData;
    if (url) {
      bodyData = params;

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/v1/${url}/?${new URLSearchParams(params)}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        });

        const responseData = await response.json();
        if (response.ok) {
          setContractDataPut(responseData);
        } else {
          setContractErrorPut(responseData.detail);
        }
      } catch (error) {
        setContractErrorPut(error.message);
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      putContractData(url, params);
    }
  }, [isLogin, token, url, params]);

  return { contractDataPut, contractErrorPut, mutate: putContractData };
}