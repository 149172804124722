// CustomerFeature.tsx
import React, { useState } from 'react';
import Layout from '../../layouts/Customer';
import { Row, Col, Drawer, FloatButton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Box, Container } from '@chakra-ui/react'
import { useParams } from "react-router-dom";
import moment from "moment";
import CustomerStats from '../Dashboard/components/CustomerStats';
import Overview from './components/Overview';
import CashFLow from './components/CashFlow';
import PortfolioSummary from './components/PortfolioSummary';
import PortfolioPerformance from './components/PortfolioPerformance';
import AssetAllocation from './components/AssetAllocation';
import Transaction from './components/Transaction';
import StockPorfolio from './components/StockPorfolio';
import PortfolioStats from './components/PortfolioStats';
import { usePortfolioStats, useAssetAllocation, useAllocatorData, useAssetStatus, usePortfolioSummary, usePortfolioPerformance, useStockPortfolio, useRevenue, useCustomerProgress } from '../../hooks/customer';

interface RouteParams {
  customerId: string;
}

const CustomerFeature: React.FC = () => {
  const { customerId }: RouteParams = useParams<RouteParams>();

  const currentDate = localStorage.getItem('currentDate')?.toString() || moment().format('YYYY-MM-DD').toString();

  const { portfolioStats } = usePortfolioStats({ account_id: customerId });
  const { assetAllocation } = useAssetAllocation({ account_id: customerId });
  const { allocatorData } = useAllocatorData({ account_id: customerId });
  const { assetStatus, mutateAssetStatus } = useAssetStatus({ account_id: customerId, date: currentDate });
  const { portfolioSummary } = usePortfolioSummary({ account_id: customerId, date: currentDate });
  const { portfolioPerformance } = usePortfolioPerformance({ account_id: customerId, date: currentDate });
  const { stockPortfolio } = useStockPortfolio({ account_id: customerId, date: currentDate });
  const { revenue } = useRevenue({ account_id: customerId, date: currentDate });
  const { customerProgress } = useCustomerProgress({ account_id: customerId, date: currentDate });

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Layout>
      <Container maxW={'container.6xl'}>
        <Row align={'middle'}>
          <Col span={12} style={{ paddingRight: '15px' }}>
            <Overview data={customerProgress} />
          </Col>
          <Col span={12} style={{ paddingLeft: '15px' }}>
            <PortfolioStats data={portfolioStats} />
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col span={24}>
            <Transaction assetStatus={assetStatus} />
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col span={12} style={{ paddingRight: '15px' }}>
            <Box boxShadow={'base'} rounded={'md'} p={2}>
              <PortfolioPerformance portfolioPerformance={portfolioPerformance} />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mt={10}>
              <AssetAllocation assetAllocation={assetAllocation} />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mt={10}>
              <StockPorfolio stockPortfolio={stockPortfolio} assetAllocation={assetAllocation} assetStatus={assetStatus} allocatorData={allocatorData} mutateAssetStatus={mutateAssetStatus} />
            </Box>
          </Col>
          <Col span={12} style={{ paddingLeft: '15px' }}>
            <Box boxShadow={'base'} rounded={'md'} p={2}>
              <PortfolioSummary portfolioSummary={portfolioSummary} />
            </Box>
            <Box boxShadow={'base'} rounded={'md'} p={2} mt={10}>
              <CashFLow data={revenue} />
            </Box>
          </Col>
        </Row>
        <FloatButton.Group
          type="primary"
          trigger="click"
          style={{ insetInlineEnd: 15, bottom: 60 }}
          icon={<PlusOutlined />}
        >
          <FloatButton onClick={showDrawer} tooltip={<div>More</div>} />
        </FloatButton.Group>
      </Container>
      <Drawer onClose={onClose} open={open} width={'90vw'} title={'Danh sách khách hàng'}>
        <Box mb={5}>
          <CustomerStats />
        </Box>
      </Drawer>
    </Layout>
  );
};

export default CustomerFeature;
