import { useState, useEffect } from 'react';
import useProvideAuth from '../useProvideAuth';
import useFetchData from './useFetchData';

export default function useRevenue(params) {
  const { isLogin } = useProvideAuth();
  const [revenue, setRevenue] = useState(null);

  const { data, error, mutate } = useFetchData('estimated-required-revenue-cashflow', params);

  useEffect(() => {
    if (data && isLogin) {
      setRevenue(data);
    }
    if (error) {
      setRevenue(null);
    }
  }, [data, error, isLogin]);

  return { revenue, mutate };
}