import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  FormControl,
  Text,
  Select,
  HStack
} from '@chakra-ui/react';
import { jwtDecode } from "jwt-decode";
import { notification, Radio } from 'antd';
import type { RadioChangeEvent } from 'antd/lib/radio/interface';
import * as xlsx from 'xlsx';
import usePostFile from '../../../../hooks/usePostFile';
import usePostData from '../../../../hooks/usePostData';
import usePostCheck from '../../../../hooks/usePostCheck';
import CTablePreview from '../../../../components/CTablePreview';
import './styles.scss';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const CUSTOMER = 'Customer';
const WATCHLIST = 'Watchlist';
const CASHFLOW = 'Cashflow';
const PORTFOLIO_DETAILS = 'Portfolio Details';
// Create a constant containing the keys of the Customer interface
const customerKeys = [
  "account_id",
  "name",
  "cust_start_date",
  "cust_end_date",
  "account_type",
  "capital",
  "hurdle_rate",
  "management_fee",
  "performance_fee",
  "commission_rate",
  "ref_code",
  "risk_level",
  "care_by"
];

const customerHeaders = [
  "ID",
  "Name",
  "Start",
  "End",
  "Type",
  "Capital",
  "Hurdle rate",
  "Mgmt. fee",
  "Perf. Fee",
  "Commission Rate",
  "Ref. code",
  "Risk level",
  "Care by"
];

// Create a constant containing the keys of the Watchlist interface
const watchlistKeys = [
  "symbol",
  "sector",
  "target_price",
  "note",
  "ref"
];

const watchListHeaders = [
  "Symbol",
  "Sector",
  "Target Price",
  "Note",
  "Ref"
];

// Create a constant containing the keys of the Cashflow interface
const cashflowKeys = [
  "account_id",
  "name",
  "date",
  "amount",
  "description"
];

const cashFlowHeaders = [
  "ID",
  "Name",
  "Date",
  "Amount",
  "Description"
];

// Create a constant containing the keys of the Portfolio Details interface
const portfolioKeys = [
  "account_id",
  "date",
  "asset_id",
  "symbol",
  "cost_price",
  "volume",
];

const portfolioHeaders = [
  "ID",
  "Date",
  "Asset ID",
  "Symbol",
  "Cost Price",
  "Volume",
];

export default function Import({ openModal, onCloseModal }: any) {

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [apiEndpoint, setApiEndpoint] = useState<string | null>(null);
  const [dataKeys, setDataKeys] = useState<any[]>([]);
  const [jsonData, setJsonData] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [excelTemplate, setExcelTemplate] = useState<JSX.Element | null>(null);
  const [excelHeader, setExcelHeader] = useState<any[]>([]);

  const jwtToken = localStorage.getItem('jwtToken') || '';
  const decoded = jwtToken ? jwtDecode(jwtToken) : null;
  const user = decoded ? (decoded as { data: any })['data'] : null;

  const { mutate: postData, data, error } = usePostData();
  const { mutate: postCheck, dataCheck, errorCheck } = usePostCheck();
  const { mutate: postFile, dataFile } = usePostFile();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, title: string, description: string | string[] | { type: string, loc: (string | number)[], msg: string, input: object }[]) => {
    let formattedDescription: string;

    if (Array.isArray(description)) {
      if (typeof description[0] === 'string') {
        // Join the array elements into a single string, separated by newlines
        formattedDescription = (description as string[]).join('</br>');
      } else {
        // Format the array of error objects
        formattedDescription = (description as { type: string, loc: (string | number)[], msg: string, input: object }[])
          .map(err => `${err.loc[2]}: ${err.msg}`)
          .join('</br>');
      }
    } else {
      // If it's already a string, use it as is
      formattedDescription = description;
    }

    api[type]({
      message: title,
      description: <span>{formattedDescription}</span>,
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    setJsonData([]);
    setExcelHeader([]);
    setExcelTemplate(null);
  };

  const [typeOption, setTypeOption] = useState<string | null>(null);

  const handleTypeChange = (e: RadioChangeEvent) => {
    setTypeOption(e.target.value);
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (selectedOption === PORTFOLIO_DETAILS) {
      if (e.target.files) {
        // Convert FileList to an array
        const filesArray = Array.from(e.target.files);
        // Loop through each file and upload
        for (const file of filesArray) {
          await postFile('portfolio/details/file', file);
        }
      }
    } else {
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          if (event.target?.result) {
            const dataFile = event.target.result as ArrayBuffer;
            const workbook = xlsx.read(new Uint8Array(dataFile), { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            // Get headers from the first row
            const headers = xlsx.utils.sheet_to_json(worksheet, {
              header: 1, // Get rows as arrays, with header being the first row
              raw: true,
              defval: ''
            })[0];

            if (JSON.stringify(excelHeader) === JSON.stringify(headers)) {
              const jsonData = xlsx.utils.sheet_to_json(worksheet, {
                header: dataKeys,
                raw: true, // Convert types based on the content
                defval: '' // Set default value for empty cells to null
              });
              if (selectedOption === CASHFLOW) {
                postCheck('portfolio/cashflow/check-duplicate', jsonData.slice(1));
              } else {
                setJsonData(jsonData.slice(1)); // Update state with the parsed JSON data
              }
            } else {
              openNotificationWithIcon('error', 'Error', `${selectedOption} wrong file format!`);
            }
          }
        };
        reader.readAsArrayBuffer(e.target.files[0]); // Ensure this line is not commented out
      }
    }
  };

  useEffect(() => {
    if (dataFile) {
      setJsonData((prevJsonData) => {
        if (prevJsonData && prevJsonData.length > 0) {
          // Combine the existing jsonData with the new dataFile
          return [...prevJsonData, ...dataFile];
        } else {
          // If no existing jsonData, just set the new dataFile
          return dataFile;
        }
      });
    } else {
      setJsonData([]); // Set an empty array if dataFile is null or undefined
    }
  }, [dataFile]);

  const handleSubmit = async () => {
    const filteredData = jsonData.filter(row => !(row?.dup_infile || row?.dup_indb));
    if (filteredData.length === 0) {
      openNotificationWithIcon('error', 'Error', 'Dữ liệu không thay đổi');
      return;
    }

    try {
      await postData(apiEndpoint, filteredData); // Chỉ truyền 2 tham số

      if (selectedOption === CUSTOMER && typeOption !== '2') {
        await postData('customer/contract', filteredData);
      }

      openNotificationWithIcon('success', 'Success', 'Data imported successfully');
      onCloseModal();
      setSelectedOption(null);
      setExcelTemplate(null);
      setJsonData([]);
    } catch (error) {
      openNotificationWithIcon('error', 'Error', 'Error importing data');
    }
  };

  const handleClose = () => {
    setSelectedOption(null);
    setJsonData([]);
    setExcelTemplate(null);
    setExcelHeader([]);
    onCloseModal();
  };

  const handleRowDoubleClick = (rowIndex: number, row: any) => {
    const updatedRow = { ...row, dup_indb: false, dup_infile: false };
    const updatedData = [...jsonData];
    updatedData[rowIndex] = updatedRow;
    setJsonData(updatedData);

  };

  useEffect(() => {
    switch (selectedOption) {
      case CUSTOMER:
        setExcelTemplate(
          <Box>
            <CTablePreview tLabels="CUSTOMER" theadData={customerHeaders} tbodyData={jsonData} tableKey={customerKeys} />
          </Box>
        );
        setExcelHeader(customerHeaders);
        setDataKeys(customerKeys);
        setApiEndpoint('customer');
        break;
      case WATCHLIST:
        setExcelTemplate(
          <Box>
            <CTablePreview tLabels="WATCHLIST" theadData={watchListHeaders} tbodyData={jsonData} tableKey={watchlistKeys} />
          </Box>
        );
        setExcelHeader(watchListHeaders);
        setDataKeys(watchlistKeys);
        setApiEndpoint('portfolio/watchlist');
        break;
      case CASHFLOW:
        setExcelTemplate(
          <Box>
            <CTablePreview tLabels="CASHFLOW" theadData={cashFlowHeaders} tbodyData={jsonData} tableKey={cashflowKeys} handleRowDoubleClick={handleRowDoubleClick} />
          </Box>
        );
        setExcelHeader(cashFlowHeaders);
        setDataKeys(cashflowKeys);
        setApiEndpoint('portfolio/cashflow');
        break;
      case PORTFOLIO_DETAILS:
        setExcelTemplate(
          <Box>
            <CTablePreview tLabels="PORTFOLIO DETAILS" theadData={portfolioHeaders} tbodyData={jsonData} tableKey={portfolioKeys} />
          </Box>
        );
        setExcelHeader(portfolioHeaders);
        setDataKeys(portfolioKeys);
        setApiEndpoint('portfolio/details');
        break;
      default:
        // setExcelTemplate(null);
        // setJsonData([]);
        break;
    }
  }, [selectedOption, jsonData]);

  useEffect(() => {
    if (dataCheck !== null && dataCheck !== undefined) {
      const jsonDataCheck = dataCheck['data'] as any;
      setJsonData(jsonDataCheck);
    }
    if (errorCheck) {
      openNotificationWithIcon('error', 'Error', errorCheck);
    }
  }, [dataCheck, errorCheck]);

  useEffect(() => {
    if (data) {
      openNotificationWithIcon('success', 'Success', 'Data imported successfully');
      onCloseModal();
      setSelectedOption(null);
      setExcelTemplate(null);
      setJsonData([]);
    }
    if (error) {
      openNotificationWithIcon('error', 'Error', error);
    }
  }, [data, error]);

  return (
    <>
      {contextHolder}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={openModal}
        onClose={onCloseModal}
        size={'5xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody py={5}>
            <HStack spacing={'24px'}>
              <Box w={'289px'} boxShadow={'lg'}>
                <FormControl>
                  <Select
                    onChange={handleSelectChange}
                    placeholder={'Select one'}
                    bg={'#D9D9D9'}
                  >
                    {user?.role_name !== 'admin' ? null : <option>Customer</option>}
                    {user?.role_name !== 'admin' ? null : <option>Watchlist</option>}
                    <option>Cashflow</option>
                    <option>Portfolio Details</option>
                  </Select>
                </FormControl>
              </Box>
              <Box w={'100px'} h={'40px'}>
                <input
                  type={'file'}
                  name={'upload'}
                  id={'upload'}
                  multiple={selectedOption === PORTFOLIO_DETAILS ? true : false}
                  onChange={handleUploadFile}
                />
              </Box>
            </HStack>
            {
              selectedOption === CUSTOMER && (
                <Box mt={5}>
                  <Radio.Group
                    name="type"
                    defaultValue={'1'}
                    onChange={handleTypeChange}
                    options={[
                      { value: '1', label: 'Add new' },
                      { value: '2', label: 'Update' },
                    ]}
                  />
                </Box>
              )
            }
            <Text mt={5} fontWeight={'bold'}>Preview</Text>
            {
              jsonData.length > 0 ? (
                excelTemplate
              ) : (
                <Box height={250} bg={'#D9D9D9'} />
              )
            }
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleClose} mr={3} size={'xs'}>Cancel</Button>
            <Button bg={'#474343'} color={'#ffffff'} size={'xs'} onClick={handleSubmit}>
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}