import React, { useRef, useEffect, useState } from 'react';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import { jwtDecode } from "jwt-decode";
import { Col, Row, Table, Button, Form, Input, InputNumber, Select, DatePicker, Space, Tag, Dropdown, message, Popconfirm, Modal, notification } from 'antd';
import type { TableProps, InputRef, TableColumnType, MenuProps, PopconfirmProps } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import { NumericFormat } from 'react-number-format';
import usePutData from '../../../../hooks/usePutData';
import usePutContractData from '../../../../hooks/usePutContractData';
import { useCustomer } from '../../../../hooks';
import Moment from 'react-moment';
import moment from 'moment';
import { getLatestUpdated } from '../../../../utils/commonFunctions';
import useDeleteCustomer from '../../../../hooks/useDeleteCustomer';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import './styles.scss'

type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];
const { Option } = Select;

dayjs.extend(customParseFormat);

const dateFormat = 'YYYY-MM-DD';

interface DataType {
  key: string;
  account_id: string;
  contract_id: string;
  name: string;
  cust_start_date: Date;
  hurdle_rate: string;
  management_fee: string;
  performance_fee: string;
  commission_rate: string;
  benchmark: string;
  cust_end_date: Date;
  cur_date: Date;
  ref_code: string;
  risk_level: string;
  capital: string;
  care_by: string;
  updated_at: string;
  is_active: boolean;
}

type DataIndex = keyof DataType;

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const Customer: React.FC = () => {
  const jwtToken = localStorage.getItem('jwtToken') || '';
  const decoded = jwtToken ? jwtDecode(jwtToken) : null;
  const user = decoded ? (decoded as { data: any })['data'] : null;
  const { mutate: putData, dataPut, errorPut } = usePutData();
  const { mutate: putContractData, contractDataPut, contractErrorPut } = usePutContractData();
  const { customer, mutateCustomer } = useCustomer();
  const { mutate: deleteCustomer, dataDelete, errorDelete } = useDeleteCustomer();
  const uniqueCarebys: string[] = [];
  const uniqueRefs: string[] = [];

  const [customers, setCustomers] = useState<any[]>(customer || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<DataType | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type: NotificationType, title: string, description: string | string[] | { type: string, loc: (string | number)[], msg: string, input: object }[]) => {
    let formattedDescription: string;

    if (Array.isArray(description)) {
      if (typeof description[0] === 'string') {
        // Join the array elements into a single string, separated by newlines
        formattedDescription = (description as string[]).join('</br>');
      } else {
        // Format the array of error objects
        formattedDescription = (description as { type: string, loc: (string | number)[], msg: string, input: object }[])
          .map(err => `${err.loc[2]}: ${err.msg}`)
          .join('</br>');
      }
    } else {
      // If it's already a string, use it as is
      formattedDescription = description;
    }

    api[type]({
      message: title,
      description: <span>{formattedDescription}</span>,
    });
  };

  customers?.forEach((item: DataType) => {
    if (!uniqueCarebys.includes(item.care_by)) {
      uniqueCarebys.push(item.care_by);
    }
    if (!uniqueRefs.includes(item.ref_code) && item.ref_code !== '') {
      uniqueRefs.push(item.ref_code);
    }
  });

  const formattedCareBys = uniqueCarebys.map(symbol => ({
    text: symbol,
    value: symbol
  }));

  const formattedRefs = uniqueRefs.map(symbol => ({
    text: symbol,
    value: symbol
  }));

  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: '#ffffff' }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#ffffff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const confirm = (record: DataType) => {
    return () => {
      putData('customer/status', {
        account_id: record.account_id,
        status: !record.is_active,
      });
    };
  };

  useEffect(() => {
    mutateCustomer();
    if (customer !== null) {
      setCustomers(customer);
    }
  }, [customer, dataPut, contractDataPut, dataDelete]);

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'account_id',
      key: 'account_id',
      align: 'center',
      fixed: 'left',
      ...getColumnSearchProps('account_id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      fixed: 'left',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Start',
      dataIndex: 'cust_start_date',
      key: 'cust_start_date',
      align: 'center',
      width: 100,
      sorter: (a, b) => moment(a.cust_start_date).unix() - moment(b.cust_start_date).unix(),
      render: (text) => <Moment format={'DD/MM/YYYY'}>{text}</Moment>,
    },
    {
      title: 'Capital',
      dataIndex: 'capital',
      key: 'capital',
      align: 'right',
      sorter: (a, b) => parseFloat(a.capital) - parseFloat(b.capital),
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Hurdle rate',
      dataIndex: 'hurdle_rate',
      key: 'hurdle_rate',
      align: 'right',
      render: (text) => <CNumberToPercent number={text} />,
    },
    {
      title: 'Mgmt. fee',
      dataIndex: 'management_fee',
      key: 'management_fee',
      align: 'right',
      render: (text) => <CNumberToPercent number={text} />,
    },
    {
      title: 'Perf. fee',
      dataIndex: 'performance_fee',
      key: 'performance_fee',
      align: 'right',
      render: (text) => <CNumberToPercent number={text} />,
    },
    {
      title: 'Benchmark',
      dataIndex: 'benchmark',
      key: 'benchmark',
      align: 'right',
      render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Ref.',
      dataIndex: 'ref_code',
      key: 'ref_code',
      align: 'center',
      filters: formattedRefs,
      filterSearch: true,
      onFilter: (value, record) => record.ref_code.startsWith(value as string),
    },
    {
      title: 'Risk level',
      dataIndex: 'risk_level',
      key: 'risk_level',
      align: 'center',
      filters: [
        {
          text: 'Low',
          value: 'low',
        },
        {
          text: 'Medium',
          value: 'medium',
        },
        {
          text: 'High',
          value: 'high',
        },
      ],
      onFilter: (value, record) => record.risk_level.startsWith(value as string),
    },
    {
      title: 'Care by',
      dataIndex: 'care_by',
      key: 'care_by',
      align: 'center',
      ellipsis: true,
      hidden: user?.role_name !== 'admin',
      filters: formattedCareBys,
      filterSearch: true,
      onFilter: (value, record) => record.care_by.startsWith(value as string),
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      align: 'center',
      fixed: 'right',
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_active === value,
      render: (text, record) => (
        <>
          <Popconfirm
            title="Update customer status"
            description="Are you sure to change customer status"
            onConfirm={(e) => {
              e?.stopPropagation();
              confirm(record)();
            }}
            onCancel={(e) => e?.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <Tag color={record?.is_active ? "green" : "red"} className="ignore-click">
              {record?.is_active ? "Active" : "Inactive"}
            </Tag>
          </Popconfirm>
        </>
      ),
    }
  ];

  const confirmDelete: PopconfirmProps['onConfirm'] = (e) => {
    setLoading(true);
    deleteCustomer('customer/contract', {
      contract_ids: selectedRowKeys
    });
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const cancelDelete: PopconfirmProps['onCancel'] = (e) => {
    message.error('Click on No');
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const items: MenuProps['items'] = [
    {
      label: (
        <Popconfirm
          title="Delete the task"
          description="Are you sure to delete this task?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" danger>Delete</Button>
        </Popconfirm>
      ),
      key: '0',
    }
  ];

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await putContractData('customer/contract/edit', {
        contract_id: selectedRow?.contract_id,
        cust_start_date: values?.cust_start_date.format('YYYY-MM-DD'),
        cust_end_date: values?.cust_end_date.format('YYYY-MM-DD'),
        hurdle_rate: values?.hurdle_rate,
        management_fee: values?.management_fee,
        performance_fee: values?.performance_fee,
        capital: values?.capital,
        commission_rate: values?.commission_rate
      });

      setSelectedRow(null);
    } catch (error) {
      console.error("Lỗi khi cập nhật hợp đồng:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contractDataPut) {
      openNotificationWithIcon('success', 'Success', 'Data updated successfully');
      setIsModalOpen(false);
    }
    if (contractErrorPut) {
      openNotificationWithIcon('error', 'Error', contractErrorPut);
    }
  }, [contractDataPut, contractErrorPut]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedRow) {
      form.setFieldsValue({
        cust_start_date: dayjs(selectedRow.cust_start_date, dateFormat),
        cust_end_date: dayjs(selectedRow.cust_end_date, dateFormat),
        hurdle_rate: selectedRow.hurdle_rate,
        management_fee: selectedRow.management_fee,
        performance_fee: selectedRow.performance_fee,
        capital: selectedRow.capital,
        commission_rate: selectedRow.commission_rate,
      });
    }
  }, [selectedRow, form]);

  return (
    <>
      {contextHolder}
      <Space style={{ marginBottom: '10px' }}>
        <Dropdown menu={{ items }} trigger={['click']} disabled={!hasSelected}>
          <Button onClick={(e) => e.preventDefault()}>
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
        {hasSelected ? `Selected ${selectedRowKeys.length} items` : null}
      </Space>
      <Table
        rowKey={'contract_id'}
        rowSelection={rowSelection}
        className={'c-ant-table'}
        columns={columns}
        dataSource={customers}
        size={'small'}
        tableLayout={'auto'}
        scroll={{ x: 'max-content' }}
        bordered
        onRow={(record) => ({
          onClick: (event) => {
            const target = event.target as HTMLElement;
            if (target?.closest('.ignore-click')) {
              return;
            }
            setSelectedRow(record);
            setIsModalOpen(true);
          },
        })}
        title={() => (
          <Row>
            <Col span={12} className={'left'}>
              CUSTOMER
            </Col>
            <Col span={12} className={'right'}>
              Last updated: <Moment format={'DD/MM/YYYY'}>{getLatestUpdated(customers)}</Moment>
            </Col>
          </Row>
        )} />
      <Modal
        title={selectedRow && (
          <>
            <p><b>{selectedRow?.name}</b> - {selectedRow?.account_id}</p>
          </>
        )}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setSelectedRow(null);
        }}
        footer={null}
      >
        <>
          <Form
            name="complex-form"
            onFinish={onFinish}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            style={{ maxWidth: 600 }}
            form={form}
          >
            <Form.Item label="Ngày bắt đầu hợp đồng">
              <Space>
                <Form.Item
                  name="cust_start_date"
                  noStyle
                  rules={[
                    { required: true, message: 'Field is required' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || !getFieldValue('cust_end_date')) {
                          return Promise.resolve();
                        }
                        if (value.isBefore(getFieldValue('cust_end_date'))) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Ngày bắt đầu phải nhỏ hơn ngày kết thúc!'));
                      },
                    }),
                  ]}
                >
                  <DatePicker style={{ width: 230 }} format={dateFormat} />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item label="Ngày kết thúc hợp đồng">
              <Space>
                <Form.Item
                  name="cust_end_date"
                  noStyle
                  rules={[
                    { required: true, message: 'Field is required' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || !getFieldValue('cust_start_date')) {
                          return Promise.resolve();
                        }
                        if (value.isAfter(getFieldValue('cust_start_date'))) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Ngày kết thúc phải lớn hơn ngày bắt đầu!'));
                      },
                    }),
                  ]}
                >
                  <DatePicker style={{ width: 230 }} format={dateFormat} />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item label="Lợi nhuận yêu cầu">
              <Space>
                <Form.Item
                  name="hurdle_rate"
                  noStyle
                  rules={[{ required: true, message: 'Field is required' }]}
                >
                  <InputNumber min={0} style={{ width: 230 }} placeholder="Giá trị khả dĩ: 0.1, 0.2, 0.3, 0.4, 0.5" />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item label="Phí quản lý">
              <Space>
                <Form.Item
                  name="management_fee"
                  noStyle
                  rules={[{ required: true, message: 'Field is required' }]}
                >
                  <InputNumber min={0} style={{ width: 230 }} placeholder="Giá trị khả dĩ: 0.1, 0.2, 0.3, 0.4, 0.5" />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item label="Phí lợi nhuận">
              <Space>
                <Form.Item
                  name="performance_fee"
                  noStyle
                  rules={[{ required: true, message: 'Field is required' }]}
                >
                  <InputNumber min={0} style={{ width: 230 }} placeholder="Giá trị khả dĩ: 0.1, 0.2, 0.3, 0.4, 0.5" />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item label="Tiền vốn thoả thuận trên hợp đồng">
              <Space>
                <Form.Item
                  name="capital"
                  noStyle
                  rules={[{ required: true, message: 'Field is required' }]}
                >
                  <InputNumber min={0} style={{ width: 230 }} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item label="Phí quảng cáo">
              <Space>
                <Form.Item
                  name="commission_rate"
                  noStyle
                  rules={[{ required: true, message: 'Field is required' }]}
                >
                  <InputNumber min={0} style={{ width: 230 }} placeholder="Giá trị khả dĩ: 0.1, 0.2, 0.3, 0.4, 0.5" />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item label={null}>
              <Button loading={loading} type="primary" htmlType="submit">
                Gia hạn
              </Button>
            </Form.Item>
          </Form>
        </>
      </Modal>
    </>
  );
};

export default Customer;
